
import debounce from 'lodash/debounce';
import { mapGetters } from 'vuex';

import { highlight } from '@/utils/highlight';
import { isEmptyData, jsonToArray } from '@/utils/utils';

import UserMetaUnivAndMajorInput from '@/components/common/atoms/UserMetaUnivAndMajorInput.vue';

import layoutMixin from '@/mixins/layoutMixin';

import '@/plugins/directives/click_outside';

export default {
	name: 'UserMetaUnivAndMajor',
	mixins: [layoutMixin],
	props: {
		univ: {
			type: String,
			default: '',
		},
		major: {
			type: String,
			default: '',
		},
		isValidUnivAndMajor: {
			type: Boolean,
			default: false,
		},
		marketing: {
			type: Boolean,
		},
		isAgreedMarketing: {
			type: Boolean,
		},
		isNewbiePartner: {
			type: Boolean,
		},
	},
	data() {
		return {
			univList: [],
			majorList: [],
			showUniv: false,
			showMajor: false,
			showAppend: false,
			userUnivTextValue: '',
			userMajorTextValue: '',
			isFetchingUniv: false,
			isFetchingMajor: false,
			isPartnerApplyAble: null, // 제휴 가능 대학인지
			isCheckedPartnerApplyAble: false,
		};
	},
	computed: {
		...mapGetters(['isPartner', 'isMentee']),
		sync_univ: {
			get() {
				return this.univ;
			},
			set(value) {
				return this.$emit('update:univ', value);
			},
		},
		sync_major: {
			get() {
				return this.major;
			},
			set(value) {
				return this.$emit('update:major', value);
			},
		},
		sync_marketing: {
			get() {
				return this.marketing;
			},
			set(value) {
				return this.$emit('update:marketing', value);
			},
		},
		sync_isNewbiePartner: {
			get() {
				return this.isNewbiePartner;
			},
			set(value) {
				// 첫 제휴 신청인지
				return this.$emit('update:isNewbiePartner', value);
			},
		},
		isOpenSchoolNameDropdown() {
			return this.isFetchingUniv || this.showUniv;
		},
		isOpenMajorDropdown() {
			return this.isFetchingMajor || this.showMajor;
		},
		hasUniv() {
			return !isEmptyData(this.sync_univ) && this.sync_univ.trim().length > 0;
		},
		hasMajor() {
			return !isEmptyData(this.sync_major) && this.sync_major.trim().length > 0;
		},
		hasUnivAndMajor() {
			return this.hasUniv && this.hasMajor;
		},
		isValid() {
			return this.isCheckedPartnerApplyAble && this.hasUnivAndMajor;
		},
	},
	watch: {
		univ() {
			this.handleCheckPartnerApplyAble();
		},
		isValid() {
			this.$emit('update:isValidUnivAndMajor', this.isValid);
		},
		isPartnerApplyAble(newVal) {
			// 현재 제휴는 아니나 대학이 제휴가능한 경우
			this.sync_isNewbiePartner = !this.isPartner && this.isMentee && newVal;
		},
	},
	mounted() {
		this.handleCheckPartnerApplyAble();
	},
	methods: {
		highlight,
		isEmptyData,
		handleCheckPartnerApplyAble() {
			this.isCheckedPartnerApplyAble = false;
			if (this.hasUniv && this.sync_univ.trim().length > 3) {
				this.checkPartnerApplyAble();
			}
		},
		async handleUnivAutocomplete() {
			if (!this.userUnivTextValue) {
				this.univList = [];
				return;
			}
			this.univList = await this.getUnivAutocomplete(this.userUnivTextValue);
		},
		async getUnivAutocomplete(keyword) {
			this.isFetchingUniv = true;
			try {
				const params = { keyword };
				const { data } = await this.$api.autocomplete.getAutocompleteUniv(params);
				return jsonToArray(data);
			} catch (error) {
				console.error(error);
				return [];
			} finally {
				this.isFetchingUniv = false;
				this.showUniv = true;
			}
		},
		async handleMajorAutocomplete() {
			if (!this.userMajorTextValue) {
				this.majorList = [];
				return;
			}
			this.majorList = await this.getMajorAutocomplete(this.userMajorTextValue);
		},
		async getMajorAutocomplete(keyword) {
			this.isFetchingMajor = true;
			try {
				const params = { keyword };
				const { data } = await this.$api.autocomplete.getAutocompleteMajor(params);
				return jsonToArray(data);
			} catch (error) {
				console.error(error);
				return [];
			} finally {
				this.isFetchingMajor = false;
				this.showMajor = true;
			}
		},
		checkPartnerApplyAble: debounce(async function () {
			this.isPartnerApplyAble = await this.getPartnerApplyAble();
		}, 300),
		async getPartnerApplyAble() {
			// 제휴 가능 대학
			try {
				const params = { univ: this.sync_univ };
				const {
					data: { result },
				} = await this.$api.case.checkPartnerApplyAble(params);
				return result;
			} catch (error) {
				return false;
			} finally {
				this.isCheckedPartnerApplyAble = true;
			}
		},
		hideDropdownUniv() {
			if (this.showUniv) {
				this.showUniv = false;
				this.userUnivTextValue = '';
				this.univList = [];
			}
		},
		hideDropdownMajor() {
			if (this.showMajor) {
				this.showMajor = false;
				this.userMajorTextValue = '';
				this.majorList = [];
			}
		},
		clickUnivItem(item) {
			this.sync_univ = item;
			this.userUnivTextValue = '';
			this.univList = [];
			this.showUniv = false;
		},
		clickMajorItem(item) {
			this.sync_major = item;
			this.userMajorTextValue = '';
			this.majorList = [];
			this.showMajor = false;
		},
	},
	components: { UserMetaUnivAndMajorInput },
};
