
import { GET_USER_META_CAREER, mapGettersOfUserMeta } from '@/store/userMeta';

import gtmTriggerMixin from '@/mixins/gtmTriggerMixin';
import layoutMixin from '@/mixins/layoutMixin';
import routeMixin from '@/mixins/routeMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

import Skeleton from '@/src/components/Other/Skeleton.vue';

export default {
	name: 'UserMetaCampRandomList',
	mixins: [routeMixin, gtmTriggerMixin, userInfoMixin, layoutMixin],
	data() {
		return {
			campRandomList: [],
		};
	},
	computed: {
		...mapGettersOfUserMeta([GET_USER_META_CAREER]),
		isCareer() {
			return this[GET_USER_META_CAREER] === 2;
		},
		typeChangeUserMetaText() {
			if (this.isCareer) {
				return '실무 스킬업을 위한 랜선 사수 클래스';
			} else {
				return '진짜 현직자와 찐하게 직무를 경험하세요';
			}
		},
	},
	created() {
		this.handleGetEduCampRandomList();
	},
	methods: {
		async handleGetEduCampRandomList() {
			const campRandomList = await this.getCampRandomList();
			this.campRandomList = this.isCareer ? campRandomList : campRandomList.slice(0, 2);
		},
		getCampRandomList() {
			if (this.isCareer) {
				return this.getPtList();
			} else {
				return this.getEduCampRandomList();
			}
		},
		handleGoEduDetail({ url, no, id, camp_title, title }) {
			// GTM - clickEduBanner
			this.$_clickEduBanner({
				user_no: this.userNo,
				user_type: this.userType,
				email: this.userId,
				banner_type: 'popup',
				banner_at: 'updateProfile',
				banner_to: this.isCareer ? 'viewEduDetail_CBC' : 'viewEduDetail',
				camp_id: null,
				group_id: this.isCareer ? id : no,
				banner_to_detail_title: this.isCareer ? camp_title : title,
				local_storage_id: this.comentoLocalStorageId,
			});
			this.$_routeMixin_href(url);
		},
		clickGotoList() {
			if (this.isCareer) {
				this.$_routeMixin_open_page('/class/pt');
			} else {
				this.$_routeMixin_go_page('/edu');
			}
		},
		async getPtList() {
			try {
				const {
					data: { list },
				} = await this.$api.classPt.getPtList();
				return list;
			} catch (error) {
				console.error(error);
			}
		},
		async getEduCampRandomList() {
			try {
				const {
					data: { list },
				} = await this.$api.camp.getEduCampRandomList();
				return list;
			} catch (error) {
				console.error(error);
			}
		},
	},
	components: { Skeleton },
};
