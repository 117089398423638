
import layoutMixin from '@/mixins/layoutMixin';

export default {
	name: 'UserMetaCareer',
	mixins: [layoutMixin],
	props: {
		career: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			careerOptions: [
				{
					label: '네, 경력이 있어요.',
					value: 2,
				},
				{
					label: '아니요, 신입이에요.',
					value: 1,
				},
			],
		};
	},
	methods: {
		handleClickCareer(value) {
			this.$emit('update:career', value);
		},
	},
};
