
import { TweenMax, Power2, Back } from 'gsap';

import { COMMUNITY_UPDATE_PROFILE_STEP } from '@/constants/community/index';
import { updateProfileTopCategoryOrderArray } from '@/constants/edu/index';
import { CAREER, INTEREST_DEPARTMENT, NICKNAME, UNIV, MAJOR, MARKETING } from '@/constants/user-meta';

import { baseUrl } from '@/plugins/constants';

import { orderArrayByIndex } from '@/utils/edu/category';
import { isEmptyData } from '@/utils/utils';

import { SET_CASE_LIST_INFO, mapMutationsOfCase } from '@/store/case';
import {
	GET_IS_SHOW_USER_META_MODAL,
	mapGettersOfUserMeta,
	mapMutationsOfUserMeta,
	GET_USER_META_CAREER,
	GET_USER_META_INTEREST_DEPARTMENT,
	GET_USER_META_NICKNAME,
	GET_USER_META_UNIV,
	GET_USER_META_MAJOR,
	GET_USER_META_MARKETING,
	SET_USER_META,
	GET_USER_META_STEP,
	SET_USER_META_STEP,
	SET_IS_SHOW_USER_META_MODAL,
} from '@/store/userMeta';

import UserMetaCareer from '@/components/common/molecules/userMetaModal/content/UserMetaCareer.vue';
import UserMetaInterestDepartment from '@/components/common/molecules/userMetaModal/content/UserMetaInterestDepartment.vue';
import UserMetaNickname from '@/components/common/molecules/userMetaModal/content/UserMetaNickname.vue';
import UserMetaUniv from '@/components/common/molecules/userMetaModal/content/UserMetaUnivAndMajor.vue';
import UserMetaCampRandomList from '@/components/common/molecules/userMetaModal/UserMetaCampRandomList.vue';

import gtmPotentialPartnerUserMixin from '@/mixins/gtm/gtmPotentialPartnerUserMixin';
import gtmTriggerMixin from '@/mixins/gtmTriggerMixin';
import layoutMixin from '@/mixins/layoutMixin';
import loadingMixin from '@/mixins/loadingMixin';
import myPageMixin from '@/mixins/myPageMixin';
import routeMixin from '@/mixins/routeMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

const STEPS = [
	0,
	COMMUNITY_UPDATE_PROFILE_STEP.CAREER,
	COMMUNITY_UPDATE_PROFILE_STEP.INTEREST_DEPARTMENT,
	COMMUNITY_UPDATE_PROFILE_STEP.NICKNAME,
	COMMUNITY_UPDATE_PROFILE_STEP.UNIV,
];

export default {
	name: 'UserMetaModal',
	mixins: [
		loadingMixin,
		gtmTriggerMixin,
		userInfoMixin,
		routeMixin,
		layoutMixin,
		myPageMixin,
		gtmPotentialPartnerUserMixin,
	],
	data() {
		return {
			baseUrl,
			isLoading: false,
			progress: 1, // progressBar step (확장성을 위해 step과 분리함)
			// 0 제외
			progressLength: STEPS.length - 1,
			jobTopCategory: null,
			jobMidCategory: null,
			isValidNickname: false,
			isNewbiePartner: false,
			isValidUnivAndMajor: false,
			isAgreedMarketing: false,
			userMetaContent: {
				[COMMUNITY_UPDATE_PROFILE_STEP.CAREER]: {
					infoParameterName: CAREER,
					title: `경력이 있으신가요?`,
					component: 'UserMetaCareer',
				},
				[COMMUNITY_UPDATE_PROFILE_STEP.INTEREST_DEPARTMENT]: {
					infoParameterName: INTEREST_DEPARTMENT,
					title: `관심있는 직무가 있으세요?`,
					plural: '최대 5개까지 추가할 수 있어요.',
					component: 'UserMetaInterestDepartment',
				},
				[COMMUNITY_UPDATE_PROFILE_STEP.NICKNAME]: {
					infoParameterName: NICKNAME,
					title: `코멘토에서 사용하고 싶은<br />닉네임을 알려주세요.`,
					component: 'UserMetaNickname',
				},
				[COMMUNITY_UPDATE_PROFILE_STEP.UNIV]: {
					infoParameterName: UNIV,
					title: `학교와 전공을 알려주세요.`,
					component: 'UserMetaUniv',
				},
			},
			eduTopCategories: [],
		};
	},
	computed: {
		...mapGettersOfUserMeta([
			GET_IS_SHOW_USER_META_MODAL,
			GET_USER_META_CAREER,
			GET_USER_META_STEP,
			GET_USER_META_INTEREST_DEPARTMENT,
			GET_USER_META_NICKNAME,
			GET_USER_META_UNIV,
			GET_USER_META_MAJOR,
			GET_USER_META_MARKETING,
		]),
		isShowUserMetaModal: {
			get() {
				return this[GET_IS_SHOW_USER_META_MODAL];
			},
			set(value) {
				if (!value) {
					this[SET_USER_META_STEP](1);
				}
				return this[SET_IS_SHOW_USER_META_MODAL](value);
			},
		},
		career: {
			get() {
				return this[GET_USER_META_CAREER];
			},
			set(value) {
				return this[SET_USER_META]({ [CAREER]: value });
			},
		},
		interestDepartment: {
			get() {
				return this[GET_USER_META_INTEREST_DEPARTMENT];
			},
			set(value) {
				return this[SET_USER_META]({ [INTEREST_DEPARTMENT]: value });
			},
		},
		nickname: {
			get() {
				return this[GET_USER_META_NICKNAME];
			},
			set(value) {
				return this[SET_USER_META]({ [NICKNAME]: value });
			},
		},
		univ: {
			get() {
				return this[GET_USER_META_UNIV];
			},
			set(value) {
				return this[SET_USER_META]({ [UNIV]: value });
			},
		},
		major: {
			get() {
				return this[GET_USER_META_MAJOR];
			},
			set(value) {
				return this[SET_USER_META]({ [MAJOR]: value });
			},
		},
		marketing: {
			get() {
				return this[GET_USER_META_MARKETING];
			},
			set(value) {
				return this[SET_USER_META]({ [MARKETING]: value });
			},
		},
		step: {
			get() {
				return this[GET_USER_META_STEP];
			},
			set(value) {
				return this[SET_USER_META_STEP](value);
			},
		},
		isDisabledNext() {
			// 버튼 유효성 검사
			switch (this.STEPS[this.step]) {
				case COMMUNITY_UPDATE_PROFILE_STEP.CAREER:
					return isEmptyData(this.career);
				case COMMUNITY_UPDATE_PROFILE_STEP.INTEREST_DEPARTMENT:
					return this.interestDepartment.length < 1;
				case COMMUNITY_UPDATE_PROFILE_STEP.NICKNAME:
					return !this.isValidNickname;
				case COMMUNITY_UPDATE_PROFILE_STEP.UNIV:
					return !this.isValidUnivAndMajor;
				default:
					return true;
			}
		},
		isFinish() {
			return this.step > this.progressLength;
		},
		CTAButtonText() {
			const isLastStep = this.progress === this.progressLength;
			const progressText = `${isLastStep ? '완료' : '다음'}`;
			const progressStatus = `(${this.progress}/${this.progressLength})`;
			return `${progressText}${this.IS_MOBILE ? ` ${progressStatus}` : ''}`;
		},
	},
	watch: {
		isShowUserMetaModal() {
			if (this.isShowUserMetaModal) {
				this.showUpdateProfileGtm();
				// this.step = 4;
				// this.progress = 4;

				if (!this.isFinish) {
					this.handleSetUserMetaInfo(this.step);
				}
			}
		},
	},
	// watch에서 감지되지 않을 경우, created 훅에서 info set
	created() {
		this.STEPS = STEPS;
		if (this.isShowUserMetaModal) {
			this.handleSetUserMetaInfo(this.step);
		}
	},
	updated() {
		if (this.isShowUserMetaModal && !this.isFinish) {
			this.startInterval();
		}
	},
	methods: {
		...mapMutationsOfUserMeta([SET_USER_META, SET_USER_META_STEP, SET_IS_SHOW_USER_META_MODAL]),
		...mapMutationsOfCase([SET_CASE_LIST_INFO]),
		showUpdateProfileGtm() {
			this.$_showUpdateProfile({
				user_no: this.userNo,
				user_type: this.userType,
				category: this.userMetaContent[this.STEPS[1]].title,
			});
		},
		async getUserMetaInfo(step) {
			try {
				this.isLoading = true;
				const params = {
					info_parameter_name: this.userMetaContent[this.STEPS[step]].infoParameterName,
				};
				const { data } = await this.$api.userMeta.getUserMetaInfo(params);
				return data;
			} catch (error) {
				console.error(error);
				// datadog logging용
				this.$customLogger(
					'메타 정보 가져오기 실패',
					{
						userNo: this.userNo,
						step,
						data: this.userMetaContent,
						err: error.response?.data,
						status: error.response?.status,
						isApp: this.IS_APP,
					},
					'debug',
				);
				return {};
			} finally {
				this.isLoading = false;
			}
		},
		/*
		 * userMeta 불러오기
		 * step 데이터 저장
		 * */
		async handleSetUserMetaInfo(step) {
			const { type, content } = await this.getUserMetaInfo(step);

			if (!isEmptyData(content)) {
				if (type === CAREER) {
					this.career = parseInt(content);
				} else if (type === INTEREST_DEPARTMENT) {
					this.interestDepartment = this.handleArray(content);
				} else if (type === NICKNAME) {
					this.nickname = content;
				} else if (type === UNIV) {
					const { univ, major, marketing } = content;
					this.univ = univ;
					this.major = major;
					this.marketing = Boolean(marketing);
					this.isAgreedMarketing = Boolean(marketing);
				}
			}
		},
		handleArray(value) {
			if (Array.isArray(value)) {
				return value;
			} else if (typeof value === 'string') {
				return value.split(',');
			}
			return [value];
		},
		async handleButtonClick() {
			const params = await this.handleGetParams();
			const { normalize } = await this.postUserMetaUpdateOrCreate(params);

			if (!isEmptyData(normalize)) {
				const { topCategory, midCategory } = normalize;
				this.jobTopCategory = topCategory;
				this.jobMidCategory = midCategory;
			}

			await this.handleUserMetaGtm();

			if (this.step < this.progressLength) {
				await this.handleSetUserMetaInfo(this.step + 1);
				await this.handleNextStep();
			} else {
				await this.$_handleTriggerPotentialPartnerUser({
					univ: this[GET_USER_META_UNIV],
					major: this[GET_USER_META_MAJOR],
				});
				if (this.isNewbiePartner) {
					this.isLoading = true;
					const TOAST_SECONDS = 3000;
					Toast.show({
						message: '저장되었습니다.',
						type: 'basic',
						position: this.IS_MOBILE ? 'bottom' : 'top',
						timeout: TOAST_SECONDS,
						showIcon: false,
					});

					setTimeout(() => {
						// 첫 제휴면 제휴 랜딩 페이지로 보내기

						if (this.career === 2) {
							this.$_routeMixin_open_page(`${baseUrl}/mentor`);
						} else {
							this.$_routeMixin_open_page(`${baseUrl}/affiliated-university`);
						}

						this.closeUserMetaModal();
					}, TOAST_SECONDS);
				} else {
					this.handleNextStep();
					// 추천 캠프 보여주기
					setTimeout(() => {
						this.closeUserMetaModal();
					}, 5000);
				}
			}
		},
		closeUserMetaModal() {
			if (this.career === 2) {
				this.$_routeMixin_open_page(`${baseUrl}/mentor`);
			}
			this.isShowUserMetaModal = false;
		},
		startInterval() {
			const progressBar = this.$refs[`progress-bar-${this.progress}`];
			const progressBarWidth = 100 / this.progressLength + '%';
			if (this.progress === this.progressLength) {
				TweenMax.to(progressBar, 1, { ease: Power2.easeOut, width: progressBarWidth });
			} else {
				TweenMax.to(progressBar, 1, { ease: Back.easeOut.config(1), width: progressBarWidth });
			}
		},
		handleGetParams() {
			const params = {
				[COMMUNITY_UPDATE_PROFILE_STEP.CAREER]: { [CAREER]: this.career },
				[COMMUNITY_UPDATE_PROFILE_STEP.INTEREST_DEPARTMENT]: { [INTEREST_DEPARTMENT]: this.interestDepartment },
				[COMMUNITY_UPDATE_PROFILE_STEP.NICKNAME]: { [NICKNAME]: this.nickname },
				[COMMUNITY_UPDATE_PROFILE_STEP.UNIV]: {
					[UNIV]: this.univ,
					[MAJOR]: this.major,
					[MARKETING]: this.marketing,
				},
			};
			return params[this.STEPS[this.step]];
		},
		async postUserMetaUpdateOrCreate(params) {
			try {
				this.isLoading = true;
				const { data } = await this.$api.userMeta.postUserMetaUpdateOrCreate(params);
				return data;
			} catch (error) {
				console.error(error);
				// datadog logging용
				this.$customLogger(
					'유저 메타 생성 및 업데이트 실패',
					{
						userNo: this.userNo,
						params,
						error,
						err: error.response?.data,
						status: error.response?.status,
						isApp: this.IS_APP,
					},
					'debug',
				);
				return {};
			} finally {
				this.isLoading = false;
			}
		},
		replaceBrToSpace(text) {
			return text.replace(/(<br>|<br\/>|<br \/>)/g, ' ');
		},
		handleNextStep() {
			this.step += 1;
			this.progress += 1;
		},
		async handleUserMetaGtm() {
			const user_no = this.userNo;
			const user_type = this.userType;

			if (this.step < this.progressLength) {
				// category: 다음 step 질문 (언제 이탈하는지를 확인하기 위함)
				const category = await this.replaceBrToSpace(this.userMetaContent[this.STEPS[this.step + 1]].title);

				switch (this.STEPS[this.step]) {
					case COMMUNITY_UPDATE_PROFILE_STEP.CAREER:
						this.$_updateProfile_2({
							user_no,
							user_type,
							category,
						});
						break;
					case COMMUNITY_UPDATE_PROFILE_STEP.INTEREST_DEPARTMENT:
						this.$_updateProfile_4({
							user_no,
							user_type,
							category,
						});
						break;
					case COMMUNITY_UPDATE_PROFILE_STEP.NICKNAME:
						this.$_updateProfile_6({
							user_no,
							user_type,
							category,
						});
						break;
				}
			} else {
				const user_career = this.career === 1 ? '신입' : '경력';
				if (this.jobTopCategory === null) {
					this.$customLogger(
						'userMetaModal jobTopCategory is Null',
						{
							user_no: this.userNo,
							user_type: this.userType,
							user_career,
							user_university: this.univ,
							user_major: this.major,
							user_company: this.company,
							user_nickname: this.nickname,
							step: this.step,
						},
						'error',
					);
				}

				this.$_updateProfile({
					user_no: this.userNo,
					user_type: this.userType,
					user_career,
					user_university: this.univ,
					user_major: this.major,
					user_company: this.company,
					user_job_topCategory: this.jobTopCategory?.split(','),
					user_nickname: this.nickname,
					where: 'onboardCaseList',
				});
			}
		},
		async fetchEduTopCategories() {
			try {
				const { data: eduTopCategories } = await this.$api.camp.getEduTopCategory();
				this.eduTopCategories = orderArrayByIndex(eduTopCategories, updateProfileTopCategoryOrderArray);
			} catch (error) {
				console.error(error);
				this.eduTopCategories = [];
			}
		},
	},
	components: {
		// dynamic component라서 사용하지 않는 컴포넌트라고 eslint error가 떠서 disable함.

		UserMetaCareer,
		UserMetaInterestDepartment,
		UserMetaNickname,
		UserMetaUniv,
		/* eslint-enable */
		UserMetaCampRandomList,
	},
};
