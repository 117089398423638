
import { isEmptyData } from '@/utils/utils';

import layoutMixin from '@/mixins/layoutMixin';
import loadingMixin from '@/mixins/loadingMixin';

export default {
	name: 'UserMetaNickname',
	mixins: [layoutMixin, loadingMixin],
	props: {
		nickname: {
			type: String,
			default: '',
		},
		isValidNickname: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			hintMessage: null,
			validNickname: null,
			fetching: false,
		};
	},
	computed: {
		hasTempNickname() {
			return !(isEmptyData(this.tempNickname) || this.tempNickname.trim().length === 0);
		},
		isShowHintMessage() {
			return this.hasTempNickname && this.hintMessage;
		},
		tempNickname: {
			get() {
				return this.nickname;
			},
			set(value) {
				return this.$emit('update:nickname', value);
			},
		},
		hintMessageColor() {
			return this.isValidNickname ? 'primary' : 'error';
		},
	},
	watch: {
		tempNickname() {
			if (!isEmptyData(this.validNickname) && this.validNickname !== this.tempNickname) {
				this.hintMessage = null;
				this.updateIsValidNickname(false);
			}
		},
	},
	methods: {
		async checkValidNickname() {
			try {
				this.fetching = true;
				const params = { nickname: this.tempNickname };
				const { data } = await this.$api.userMeta.checkNicknameDuplicate(params);
				return data;
			} catch (error) {
				console.error(error);
				Toast.show({
					message: '오류가 발생했습니다.',
					type: 'error',
					position: 'top',
					timeout: 3000,
					showIcon: false,
				});
				// datadog logging용
				this.$customLogger(
					'닉네임 중복검사 실패',
					{
						userNo: this.userNo,
						nickname: this.tempNickname,
						data,
						err: error.response?.data,
						status: error.response?.status,
						isApp: this.IS_APP,
					},
					'debug',
				);
			} finally {
				this.fetching = false;
			}
		},
		async handleCheckValidateNickname() {
			const { code } = await this.checkValidNickname();

			if (code === 200 || code === 209) {
				this.hintMessage = '사용 가능한 닉네임입니다.';
				this.validNickname = this.nickname;
				this.updateIsValidNickname(true);
			} else if (code === 210) {
				this.hintMessage = '이미 사용중인 닉네임입니다.';
			} else if (code === 211) {
				this.hintMessage = '사용할 수 없는 닉네임입니다.';
			}
		},
		updateIsValidNickname(value) {
			this.$emit('update:isValidNickname', value);
		},
		inputHandler(e) {
			const target = e.currentTarget;
			const max = Number(e.currentTarget.getAttribute('maxlength'));
			if (target.value.length > max) {
				target.value = target.value.slice(0, max);
			}
			this.$emit('update:value', target.value);
		},
	},
};
